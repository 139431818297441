import React from "react"
import { Link } from "react-scroll"
import { Wrapper, Container, P, Ul, Li } from "./style"
import { LogoIcon } from "../../images"

const Footer = () => (
  <Wrapper>
    <Container>
      <Ul>
        <a
          href="https://www.lyact.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Li>運営会社</Li>
        </a>
        <a
          href="https://www.writone.jp/terms"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Li>ご利用規約</Li>
        </a>
        <a
          href="https://www.writone.jp/privacy"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Li>プライバシーポリシー</Li>
        </a>
        <a
          href="https://www.writone.jp/law"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Li>特定商取引法に基づく表示</Li>
        </a>
      </Ul>
      <Link to="home" spy={true} smooth={true} duration={1000}>
        <LogoIcon />
      </Link>
    </Container>
    <P>Copyright © 2019. All rights reserved.</P>
  </Wrapper>
)
export default Footer
