import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  position: relative;
  width: 100%;
  top: 50px;
  align-items: center;
  padding: 4rem;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    padding: 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 4rem 1rem;
  }
`
