import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  width: 100%;
  align-items: center;
  margin: 1rem 0;
`

export const Content = styled(FlexCol)`
  width: 100%;
  margin-bottom: 1.5rem;
`
// export const Select = styled.select`
//   width: 100%;
//   border: none;
//   outline: none;
//   font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
//   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
//   margin-top: 0.5rem;
//   @media screen and (max-width: 425px) {
//     margin-bottom: 0.5rem;
//   }
// `

export const Input = styled.input`
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  padding: 1rem 1.2rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin-top: 0.8rem;
  border-radius: 30px;
  @media screen and (max-width: 425px) {
    margin-bottom: 0.5rem;
    padding: 0.8rem 1.2rem;
  }
`

export const TextArea = styled.textarea`
  max-width: 100%;
  min-width: 100%;
  min-height: 8rem;
  background: #fff;
  border: none;
  outline: none;
  padding: 1rem 1.2rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin-top: 0.8rem;
  border-radius: 2rem;
  @media screen and (max-width: 425px) {
    margin-bottom: 1rem;
    padding: 0.8rem 1.2rem;
  }
`
