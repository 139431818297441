import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"
import About from "../components/about"
import Sample from "../components/sample"
import Faq from "../components/faq"
import Contact from "../components/contact"

const Index = () => (
  <Layout>
    <SEO title="Writone | ライトーンとは" />
    <Home />
    <About />
    <audio id="audio" />
    <Sample />
    <Faq />
    <Contact />
  </Layout>
)

export default Index
