import React, { useState } from "react"
import { Wrapper, Container, Icons, Items, Item } from "./style"
import { ListenerIcon, ActorIcon, WriterIcon, IPhoneImg } from "../../images"

const titles = {
  listener: [
    "オリジナル作品が１００００冊以上！",
    "好きな物語を好きな声で楽しめる！",
    "クリエイターを応援できる！",
  ],
  actor: [
    "プロ・アマチュア関係なく誰でも声優に！",
    "ボイスブックを有料販売できる！",
    "スマホで手軽に録音できる！",
  ],
  writer: [
    "プロ・アマチュア関係なく誰でも作家に！",
    "ボイスブックの有料販売で新たな収入源！",
    "新たな読者層＝『リスナー』を確保！",
  ],
}
const texts = {
  listener: [
    "恋愛、ホラー、アクション、コメディー、ファンタジー、童話、詩などカテゴリも充実！",
    "1つの物語に複数の声優が音声化できるため、同じ内容でも違った雰囲気でボイスブックを楽しめます！",
    "クリエイターに対して応援ギフトを贈ることができます。好きなクリエイターをみつけて、ぜひ応援をよろしくお願いします！",
  ],
  actor: [
    "実際に声優としてお仕事をされている方、声優を目指している方など1500以上のユーザーが登録しています！",
    "今までにつくったボイスブックの総再生回数が1,000回を超えた場合、ボイスブックを有料で販売することができます。 販売料金はアクターが自由に設定することができ、有料のボイスブックを売り上げた場合は、設定した金額の70％がアクターに還元され、残りの30%は原作使用料としてその物語を書いたライターに還元されます。",
    "サービス内で録音できるため、誰でも手軽に音声をアップすることができます！",
  ],
  writer: [
    "実際に作家としてお仕事をされている方、作家を目指している方など1500以上のユーザーが登録しています！",
    "アクターがボイスブックを自由な料金設定で販売することができます。ボイスブックを売り上げた場合は、設定した金額の70％がアクターに還元され、残りの30%は原作使用料としてその物語を書いたライターに還元されます。１つの物語に複数の声優が音声化できるため、有料販売された数だけ原作使用料が還元されます。また、アクターに対して有料販売を禁止することも可能です。",
    "「活字が苦手」「読む時間がない」といった方の耳にあなたの物語を届けましょう！",
  ],
}

const AboutItems = () => {
  const [mode, changeMode] = useState("listener")
  let img = "https://storage.googleapis.com/writone-ja-admin/liphone.png"
  switch (mode) {
    case "listener":
      img = "https://storage.googleapis.com/writone-ja-admin/liphone.png"
      break
    case "actor":
      img = "https://storage.googleapis.com/writone-ja-admin/aiphone.png"
      break
    default:
      img = "https://storage.googleapis.com/writone-ja-admin/wiphone.png"
      break
  }
  return (
    <Wrapper>
      <Icons>
        <ListenerIcon
          active={mode === "listener"}
          onClick={() => changeMode("listener")}
        />
        <ActorIcon
          active={mode === "actor"}
          onClick={() => changeMode("actor")}
        />
        <WriterIcon
          active={mode === "writer"}
          onClick={() => changeMode("writer")}
        />
      </Icons>
      <Container>
        <Items>
          {titles[mode].map((title, i) => {
            return (
              <Item mode={mode} key={i}>
                <h4>{title}</h4>
                <p style={{ marginTop: 10, marginBottom: 0 }}>
                  {texts[mode][i]}
                </p>
              </Item>
            )
          })}
        </Items>
        <IPhoneImg src={img} alt="NoImage" />
      </Container>
    </Wrapper>
  )
}
export default AboutItems
