import styled from "styled-components"
import { FlexRow, FlexCol } from "../flexWrapper"

export const Container = styled(FlexRow)`
  width: 54rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.5rem 0 3rem;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
  }
`

export const Item = styled(FlexCol)`
  position: relative;
  align-items: center;
  width: calc(19rem - 2rem);
  height: 22.5rem;
  background: #f7faff;
  border-radius: 30px;
  margin: 0.48rem;
  overflow: hidden;
  @media screen and (max-width: 425px) {
    flex-direction: row;
    width: 100%;
    height: 8.5rem;
    margin: 0.3rem;
    border-radius: 20px;
  }
`

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  @media screen and (max-width: 425px) {
    width: 8.5rem;
    height: 8.5rem;
  }
`

export const SubContainer = styled(FlexCol)`
  position: absolute;
  top: 12rem;
  width: 100%;
  padding: 1rem 1.2rem;
  @media screen and (max-width: 425px) {
    top: 0;
    left: 8.5rem;
    width: auto;
    height: 8.5rem;
  }
`

export const Title = styled.h4`
  height: 4rem;
`
