import React from "react"
import { Wrapper } from "./style"
import SampleItems from "../sampleItems"
import { Button } from "../button"

const Sample = () => (
  <Wrapper id="sample">
    <h3>実際のコンテンツを聴いてみよう！</h3>
    <SampleItems />
    <h3 style={{ marginTop: "2rem" }}>あなたが書いた物語を声で届ける。</h3>
    <h3 style={{ marginTop: "1rem" }}>あなたの声を物語で届ける。</h3>
    <h3 style={{ marginTop: "1rem", marginBottom: "3rem" }}>
      好きな物語を好きな声で楽しめる。
    </h3>
    <a href="https://www.writone.jp/" target="_blank" rel="noopener noreferrer">
      <Button>Writoneサイトへ</Button>
    </a>
    {/* <Button>作家・声優のご依頼</Button> */}
  </Wrapper>
)
export default Sample
