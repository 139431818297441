import styled from "styled-components"
import { FlexRow } from "../flexWrapper"

export const Wrapper = styled(FlexRow)`
  position: fixed;
  width: 100%;
  height: 50px;
  background: #fff;
  justify-content: flex-end;
  padding: 1rem 4rem;
  z-index: 50;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 1023px) {
    padding: 1rem 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }
`

export const HeaderTitle = styled.h4`
  font-size: 0.75rem;
  padding-left: 2rem;
  font-weight: 500;
  @media screen and (max-width: 425px) {
    padding-left: 0;
    padding-right: 1.2rem;
  }
`
