import styled from "styled-components"

export const Button = styled.button`
  width: 14rem;
  padding: 1rem;
  background: #3dccb4;
  color: #fff;
  border-radius: 40px;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 10;
  background-size: 200% 100%;
  background-image: -webkit-linear-gradient(left, transparent 50%, #fff 50%);
  -webkit-transition: background-position 0.6s cubic-bezier(0.19, 1, 0.22, 1)
      0.2s,
    color 1s ease, background-color 1s ease, letter-spacing 0.4s ease;
  :hover {
    color: #222;
    background-color: #fff;
    background-position: -100%;
    border: solid 2px #222;
  }
  @media screen and (max-width: 425px) {
    margin: 0.5rem;
  }
`
