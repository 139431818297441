import React, { useState } from "react"
import { Wrapper, FaqItems, Question, Answer } from "./style"

const questions = [
  "無料で利用できますか？",
  "アカウント登録などは必要ですか？",
  "どんな作家・声優の方が登録していますか？",
  "どんな小説・脚本・シナリオでも投稿できますか？",
  "どんな小説・脚本・シナリオでも朗読・声劇できますか？",
]
const answers = [
  "はい。無料でご利用いただけます。料金が発生するのは有料のボイスブックを聴く場合と、クリエイターに応援ギフトを贈る場合のみです。",
  "ボイスブックを聴くだけの場合は登録する必要はありません。但し、ボイスブックに対してコメントやいいねを送る場合、応援ギフトを贈る場合、作家として小説などを投稿する場合、声優として朗読・声劇する場合などはアカウント登録が必須となります。",
  "Writoneではプロ・アマ関係なく、誰でもクリエイターとして活動することができます。そのため、実際に本を出版されている方や、WEB小説投稿サイトで活動されている方、作家を目指している方、実際に声優として活動されている方、声のお仕事をされている方、声優を目指している方など、様々な方が登録しています。",
  "投稿者自身が創作し、権利を有しているものに限ります。商品等の名誉、信用、声価等を傷つけるもの、公序良俗に反するもの、法令等に違反するもの、当社及びその他第三者の著作権その他の権利を侵害するもの、本サービスの利用規約または本個別規約に違反するものは投稿できません。",
  "はい。Writone上に掲載されているものはすべて自由に朗読・声劇いただけます。",
]

export default () => {
  const [index, setState] = useState(10)
  return (
    <Wrapper id="faq">
      <h3>よくあるご質問</h3>
      <FaqItems>
        {questions.map((question, i) => {
          return (
            <div key={i}>
              <Question onClick={() => setState(i)}>{question}</Question>
              <Answer active={i === index}>{answers[i]}</Answer>
            </div>
          )
        })}
        <div key={5}>
          <Question onClick={() => setState(5)}>
            オトナ向けの小説・脚本・シナリオは投稿できますか？
          </Question>
          <Answer active={5 === index}>
            Writoneでは露骨な性的描写のあるオトナ向けコンテンツの投稿を禁止しています。Writoneが運営する別サイト
            <a
              href="https://extra.writone.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              『Writone.ex|ライトーンエキストラ』
            </a>
            でオトナ向けコンテンツが投稿可能ですので、こちらをご利用ください。
          </Answer>
        </div>
      </FaqItems>
    </Wrapper>
  )
}
