import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  position: relative;
  top: 50px;
  width: 100%;
  height: calc(100vh - 50px);
  background: #333;
  justify-content: center;
  padding: 4rem;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    padding: 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 1rem;
    overflow: hidden;
  }
`

export const Title = styled.h2`
  color: #fff;
  text-shadow: 1px 1px 2px #666;
  letter-spacing: 1.8;
  z-index: 10;
`

export const SubTitle = styled.h4`
  color: white;
  text-shadow: 1px 1px 2px #666;
  letter-spacing: 0.3rem;
  line-height: 2.5;
  margin-bottom: 4rem;
  z-index: 10;
`
