import React from "react"
import { Wrapper, Container, P } from "./style"
import ContactForm from "../contactForm"

const Contact = () => (
  <Wrapper id="contact">
    <Container>
      <h3>お問い合わせ</h3>
      <P>
        Writoneに興味をお持ちいただき、ありがとうございます。下記のフォーム項目を入力の上、
        送信ボタンを押してください。なお、内容によっては、ご連絡までお時間がかかるものがございますので、あらかじめご了承ください。
      </P>
      <ContactForm />
    </Container>
  </Wrapper>
)
export default Contact
