import { useEffect, useState } from "react"
export default () => {
  const [windowSize, setWindowSize] = useState({ width: 425, height: 0 })
  useEffect(() => {
    const isClient = typeof window !== `undefined`
    if (!isClient) {
      return false
    }
    function getSize() {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }

    function handleResize() {
      setWindowSize(getSize())
    }
    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return windowSize
}
