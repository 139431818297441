import React from "react"
import { Wrapper, AboutText } from "./style"
import AboutItems from "../aboutItems"
// import { WritoneImg } from "../../images"

const About = () => (
  <Wrapper id="about">
    <h3>Writone | ライトーンとは？</h3>
    <AboutText>
      Writoneとはライターが書いた小説・物語・脚本・シナリオなどを、アクターが朗読、声劇して配信するサービスです。
      ユーザーは配信されたボイスブックを聴くだけでなく、クリエイターとして小説の投稿、朗読もでき、
      つくったボイスブックを無料で公開することも、有料で販売することもできます。
      小説、シナリオを書く『ライター』、朗読、声劇をする『アクター』、つくられたボイスブックを楽しむ『リスナー』の、
      まったくあたらしい交流のかたちをお楽しみください。
    </AboutText>
    <AboutItems />
  </Wrapper>
)
export default About
