import React from "react"
import useWindowSize from "../useWindowSize"
import { Wrapper, HeaderTitle } from "./style"
import { Link } from "react-scroll"
import { MailIcon, TwitterIcon } from "../../images"
import { FlexRow } from "../flexWrapper"

const Header = () => {
  const { width } = useWindowSize()
  const isSp = width <= 425
  return (
    <>
      <Wrapper>
        <FlexRow>
          <Link to="about" spy={true} smooth={true} duration={1000}>
            <HeaderTitle>Writoneとは</HeaderTitle>
          </Link>
          <Link to="sample" spy={true} smooth={true} duration={1000}>
            <HeaderTitle>コンテンツサンプル</HeaderTitle>
          </Link>
          <Link to="faq" spy={true} smooth={true} duration={1000}>
            <HeaderTitle>FAQ</HeaderTitle>
          </Link>
        </FlexRow>
        <Link to="contact" spy={true} smooth={true} duration={1000}>
          {isSp ? <MailIcon /> : <HeaderTitle>お問い合わせ</HeaderTitle>}
        </Link>
      </Wrapper>
      <a
        href="https://twitter.com/Writone1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon />
      </a>
    </>
  )
}
export default Header
