import React, { useState, useEffect } from "react"
import { Container, Item, Img, SubContainer, Title } from "./style"
import { PlayIcon, PauseIcon } from "../../images"
const titles = [
  "はじめまして、こんにちは | あなたを好いた",
  "Vin douxの甘口かしましラジオ | 第一回",
  "裸電球",
]

const srcs = [
  "https://storage.googleapis.com/writone-ja-images.writone.jp/books/nIOUaWvQyM8TzHCLXJpP_800x800.png",
  "https://storage.googleapis.com/writone-ja-images.writone.jp/books/0WfDuJNt43ZWKIYm1T70_800x800.png",
  "https://storage.googleapis.com/writone-ja-images.writone.jp/books/JmsJu5TQhspwoAZypOPO_800x800.png",
]
const audios = [
  "https://storage.googleapis.com/lyact-corporate.appspot.com/suita.mp3",
  "https://storage.googleapis.com/lyact-corporate.appspot.com/vin.mp3",
  "https://storage.googleapis.com/lyact-corporate.appspot.com/denkyu.mp3",
]

const writers = ["明里好奇(アケサトコウキ)", "昼間あくび", "進藤 海(K.Shindo)"]

const actors = ["多賀真穂", "文月水咲(ふみづき みさ)", "ようじろう"]

const MeritItems = () => {
  const [playing, toggle] = useState(false)
  const [index, setIndex] = useState(7)
  const [el, setEl] = useState()
  useEffect(() => {
    const el = document.getElementById("audio")
    el.addEventListener("ended", onEnd)
    setEl(el)
    return () => {
      el.removeEventListener("ended", onEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onEnd = () => {
    toggle(false)
  }
  const play = i => {
    if (i !== index) {
      setIndex(i)
      el.src = audios[i]
      el.play()
      toggle(true)
    } else {
      el.play()
      toggle(true)
    }
  }
  const pause = () => {
    toggle(false)
    el.pause()
  }
  return (
    <Container>
      {titles.map((title, i) => {
        return (
          <Item key={i}>
            <Img src={srcs[i]} alt={title} />
            {playing && index === i ? (
              <PauseIcon onClick={() => pause()} />
            ) : (
              <PlayIcon onClick={() => play(i)} />
            )}
            <SubContainer>
              <Title>{title}</Title>
              <p>作家：{writers[i]}</p>
              <p>声優：{actors[i]}</p>
            </SubContainer>
          </Item>
        )
      })}
    </Container>
  )
}
export default MeritItems
