import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import { GirlImg } from "../../src/images"

const Layout = ({ children }) => (
  <>
    <header>
      <Header />
    </header>
    <main>
      <GirlImg
        src={"https://storage.googleapis.com/writone-ja-admin/girl.png"}
        alt="ライトーンちゃん"
      />
      {children}
    </main>
    <footer style={{ position: "absolute", width: "100%" }}>
      <Footer />
    </footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
