import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  position: relative;
  width: 100%;
  top: 50px;
  background: #fff;
  align-items: center;
  padding: 6rem 4rem;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    padding: 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 4rem 1rem;
  }
`
export const FaqItems = styled.div`
  width: 40rem;
  padding: 1rem 0;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`

export const Question = styled.p`
  margin: 0;
  padding: 1rem 1.2rem;
  border-radius: 2rem;
  color: #222;
  background: #edf2f7;
  cursor: pointer;
`

export const Answer = styled.p`
  height: ${props => (props.active ? "100%" : 0)};
  overflow: hidden;
  color: #fff;
  background: #3eccb4;
  border-radius: 2rem;
  padding: ${props => (props.active ? "1rem 1.2rem" : "0 1rem")};
  margin: ${props => (props.active ? "1rem 0" : "0.5rem 0")};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: all 0.5s;
`
