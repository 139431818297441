import React from "react"
import useWindowSize from "../useWindowSize"
import { Wrapper, Title, SubTitle } from "./style"
import { HomeImg } from "../../images"
import { Button } from "../button"

const Home = () => {
  const { width } = useWindowSize()
  const isPc = width >= 1440
  return (
    <Wrapper id="home">
      <HomeImg />
      {isPc ? (
        <Title>ボイスブックを聴ける。つくれる。</Title>
      ) : (
        <Title>
          ボイスブックを聴ける。
          <br />
          つくれる。
        </Title>
      )}
      <SubTitle>
        リスナー８００００ユーザー、作家・声優３０００クリエイター突破！
        <br />
        プロ・アマ問わず誰でも作家・声優として活動できる。
        <br />
      </SubTitle>
      <a
        href="https://www.writone.jp/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>Writoneサイトへ</Button>
      </a>
    </Wrapper>
  )
}
export default Home
