import styled from "styled-components"
import { FlexRow, FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  align-items: center;
`

export const Icons = styled(FlexRow)`
  width: 28rem;
  justify-content: space-around;
  margin: 2rem 0 1rem;
  @media screen and (max-width: 768px) {
    margin: 2rem 0;
  }
  @media screen and (max-width: 425px) {
    width: 18rem;
  }
`

export const Container = styled(FlexRow)`
  width: 58rem;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
  }
`

export const Items = styled(FlexCol)``

export const Item = styled(FlexCol)`
  width: calc(28rem - 2rem);
  background: ${props =>
    props.mode === "listener"
      ? "#3DCCB4"
      : props.mode === "actor"
      ? "#DC5D8D"
      : "#9933ff"};
  border-radius: 30px;
  padding: 1.3rem 2rem;
  margin: 0.6rem 0;
  color: #fff;
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin: 0.6rem 0;
  }
  @media screen and (max-width: 425px) {
    padding: 1rem;
    margin: 0.2rem;
    border-radius: 20px;
  }
`
