import styled, { keyframes } from "styled-components"
import Logo from "../svgs/logo.svg"
import Mail from "../svgs/mail.svg"
import Play from "../svgs/play.svg"
import Pause from "../svgs/pause.svg"
import Listener from "../svgs/listener.svg"
import Actor from "../svgs/actor.svg"
import Writer from "../svgs/writer.svg"
import Twitter from "../svgs/twitter.svg"

const move = keyframes`
  0% {
   opacity: 0;
   background-position: 0 center;
  }
  5%{
    opacity: 1;
  }
  95%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    background-position: 1720px center;;
  }
  `

export const HomeImg = styled.div`
  width: 120%;
  height: 120%;
  position: fixed;
  left: -5%;
  background-image: ${`url(https://storage.googleapis.com/writone-ja-admin/home.png)`};
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 0 center;
  transform: rotate(-5deg);
  animation: ${move} 20s linear infinite;
`

export const GirlImg = styled.img`
  position: absolute;
  z-index: 10;
  width: 55%;
  bottom: 3rem;
  right: 1rem;
  @media screen and (min-width: 1024px) {
    width: 35%;
    top: 3rem;
    right: 4rem;
  }
  @media screen and (max-width: 425px) {
    width: 80%;
    right: 1rem;
    top: 7.5rem;
  }
`

export const IPhoneImg = styled.img`
  width: 20rem;
  margin-top: 4rem;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    width: 30rem;
    transform: rotate(45deg);
  }
`

export const LogoIcon = styled(Logo)`
  width: 130px;
  height: 30px;
  background: #f7faff;
`

export const MailIcon = styled(Mail)`
  padding-top: 3px;
`

export const PlayIcon = styled(Play)`
  position: absolute;
  top: 4.5rem;
  width: 60px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 425px) {
    width: 40px;
    height: 40px;
    top: 2.8rem;
    left: 2.8rem;
  }
`

export const PauseIcon = styled(Pause)`
  position: absolute;
  top: 4.5rem;
  width: 60px;
  height: 60px;
  cursor: pointer;
  @media screen and (max-width: 425px) {
    width: 40px;
    height: 40px;
    top: 2.8rem;
    left: 2.8rem;
  }
`

export const ListenerIcon = styled(Listener)`
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.3)};
  transition: 0.4s all;
  @media screen and (min-width: 768px) {
    transform: scale(1.3);
  }
`

export const ActorIcon = styled(Actor)`
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.3)};
  transition: 0.4s all;
  @media screen and (min-width: 768px) {
    transform: scale(1.3);
  }
`

export const WriterIcon = styled(Writer)`
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.3)};
  transition: 0.4s all;
  @media screen and (min-width: 768px) {
    transform: scale(1.3);
  }
`

export const TwitterIcon = styled(Twitter)`
  position: fixed;
  z-index: 50;
  top: 4rem;
  right: 2rem;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  @media screen and (max-width: 425px) {
    width: 50px;
    right: 1rem;
    bottom: 1rem;
    top: auto;
  }
`
