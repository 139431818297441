import styled from "styled-components"
import { FlexRow, FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  bottom: 0;
  width: 100%;
  background: #f7faff;
  align-items: flex-end;
  padding: 1.5rem 4rem;
  z-index: 50;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  @media screen and (max-width: 425px) {
    padding: 1.5rem 1rem;
  }
`

export const Container = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const P = styled.p`
  font-size: 0.85rem;
  color: #718096;
  margin-top: 0.3rem;
  margin-bottom: 0;
`

export const Ul = styled(FlexRow)`
  @media screen and (max-width: 768px) {
    padding-bottom: 1.5rem;
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

export const Li = styled.p`
  color: #222;
  font-size: 0.75rem;
  margin-bottom: 0;
  padding-right: 2rem;
  @media screen and (max-width: 425px) {
    padding-right: 0;
    padding-bottom: 1rem;
  }
`
