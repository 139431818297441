import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  width: 100%;
  align-items: center;
  padding: 6rem 4rem;
  overflow: hidden;
  background: #333;
  top: 50px;
  @media screen and (max-width: 1023px) {
    padding: 6rem 3rem 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 6rem 1rem 4rem;
  }
`
export const Container = styled(FlexCol)`
  width: 40rem;
  align-items: center;
  z-index: 10;
  color: #fff;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`

export const P = styled.p`
  @media screen and (max-width: 1023px) {
    margin-right: 0;
  }
`
