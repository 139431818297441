import styled from "styled-components"
import { FlexCol } from "../flexWrapper"

export const Wrapper = styled(FlexCol)`
  position: relative;
  width: 100%;
  background: #fff;
  top: 50px;
  padding: 6rem 4rem;
  overflow: hidden;
  align-items: center;
  z-index: 20;
  @media screen and (max-width: 1023px) {
    padding: 3rem;
  }
  @media screen and (max-width: 425px) {
    padding: 4rem 1rem;
    align-items: center;
  }
`

export const AboutText = styled.p`
  width: 52rem;
  margin: 1rem 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
