import styled from "styled-components"

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`
